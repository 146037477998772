import moment from "moment"
import React from "react"

type Props = {
  className: string
  description: string
  color: string
  img: string
  data?: any
  totalsms?: any
  usedsms?: any
}

const CardsWidgetPlanDetails = ({ className, description, color, img, data, totalsms, usedsms }: Props) => (
  <div
    className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
    style={{
      backgroundColor: color,
      backgroundImage: `url('${img}')`,
    }}
  >
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        PE Details
      </div>
    </div>
    <div className='card-body d-flex align-items-end pt-0'>
      <div className='d-flex align-items-center flex-column mt-3 w-100'>
        {/* <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
          <span>SMS {usedsms} of {totalsms}</span>
          <span>{totalsms ? `${((usedsms / totalsms) * 100).toFixed(2)} %` : '0 %'}</span>
        </div>

        <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
          <div
            className='bg-white rounded h-8px'
            role='progressbar'
            style={{ width: `${((usedsms / totalsms) * 100).toFixed(2)}%` }}
            aria-valuenow={90}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div> */}
        <div>
          <span className='fs-2hx lh-1 ls-n2'>
            {data?.name}
          </span>
          <p className='opacity-75 fw-semibold fs-6'>
            {data?.pe_id}
          </p>
        </div>
      </div>
    </div>
  </div>
)

export {CardsWidgetPlanDetails}

import React, { useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { axiosClientAuth } from '../../utils'
import { useAuth } from '../../modules/auth'

const loginSchema = Yup.object().shape({
  url: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
    .required()
})

const initialValues = {
  url: '',
}

const BlacklistURLRightToolbar = () => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const { checkPermission } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const res = await axiosClientAuth.post('/main/admin/blacklisted-urls', {
          url: values.url,
        });
        console.log(res);
        if (res.data.status) {
          Swal.fire({
            titleText: 'Success!',
            text: `${res.data.data.url} is blacklisted successfully.`,
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then((isConfirm) => {
            isConfirm && setShow(false);
            window.location.reload();
          })
        }
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    },
  })

  return (
    <>
      {
        checkPermission("Global Blacklist", "can_write") &&
        <button
          className='btn btn-sm fw-bold btn-primary'
          onClick={() => {
            setShow(!show)
          }}
        >
          Blacklist URL
        </button>
      }

      {/* My Modal */}
      <Modal
        className='modal-sticky modal-ld modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={show}
        animation={true}
        centered={true}
      >
        {/* <div className='modal-header'>
          
        </div> */}
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='d-flex justify-content-end'>
            <button
              className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setShow(false)}
            >
              <KTIcon iconName='cross' className='text-gray fs-1' />
            </button>
          </div>

          <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>Blacklist URL</h1>
              <div className='text-muted fw-semibold fs-5'>
                If you need more info, please check{' '}
                <a href='#' className='fw-bold link-primary'>
                  Our Guidelines
                </a>
                .
              </div>
            </div>

            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : null}

            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark required'>URL</label>
              <input
                placeholder='Enter URL'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.url && formik.errors.url },
                  {
                    'is-valid': formik.touched.url && !formik.errors.url,
                  }
                )}
                type='text'
                name='url'
                autoComplete='off'
              />
              {formik.touched.url && formik.errors.url && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.url}</span>
                </div>
              )}
            </div>

            {/* begin::Action */}
            <div className='text-center mb-10'>
              <button type='reset' className='btn btn-light me-3' onClick={() => setShow(false)}>
                <span className='indicator-label'>Cancel</span>
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Blacklist</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default BlacklistURLRightToolbar
/* eslint-disable jsx-a11y/anchor-is-valid */
import react from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {TablesWidgetOrders} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetOrders'
import {ListsWidgetExistingLogs} from '../../../_metronic/partials/widgets/_new/lists/ListsWidgetExistingLogs'
import {CardsWidget17, StatisticsWidget3} from '../../../_metronic/partials/widgets'
import CardsDashboardMain from '../../../_metronic/partials/widgets/_new/cards/CardsDashboardMain'
import {DatePicker} from 'antd'

const PaymentReport = ({dashboardData, paymentData, setDateFilter}) => {
  return (
    <>
      <div className='mb-3 d-flex justify-content-between'>
        <h3>Payment Details</h3>
        <DatePicker.RangePicker
          onChange={(_, val) => {
            setDateFilter(val)
          }}
        />
      </div>

      <div className='row g-5 g-xl-10 mb-xl-0'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsDashboardMain data={dashboardData} className='mb-5 mb-xl-10 h-100' />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <ListsWidgetExistingLogs
            data={dashboardData?.sms_package_count}
            className='h-100 mb-5 mb-xl-10'
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <StatisticsWidget3
            className='card-xl-stretch h-100'
            color='success'
            title={dashboardData?.revenue_per_month[dashboardData?.revenue_per_month?.length - 1]}
            description='Monthly Sales'
            change='+2.4%'
            chartData={dashboardData?.revenue_per_month}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 data={dashboardData?.revenue} className='card-xl-stretch mb-xl-8 h-100' />
        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-12'>
          {paymentData && (
            <TablesWidgetOrders data={paymentData} className='card-xxl-stretch mb-5 mb-xl-10' />
          )}
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PaymentReportWrapper = ({dashboardData = {}, paymentData = [], setDateFilter = {}}) => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({id: 'Payment Report'})}
      </PageTitle>
      <PaymentReport
        dashboardData={dashboardData}
        setDateFilter={setDateFilter}
        paymentData={paymentData}
      />
    </>
  )
}

export {PaymentReportWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {EngageWidget10} from '../../../_metronic/partials/widgets'
import {CardsWidget171} from '../../../_metronic/partials/widgets/_new/cards/CardsWidget171'
import {TablesWidgetIPLogs} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetIPLogs'
import {axiosClientAuth} from '../../utils'

const IPList: FC = () => {
  const [ipData, setIpData] = useState([])
  const [ipStats, setIpStats] = useState({
    approved: 0,
    rejected: 0,
    pending: 0,
  })

  useEffect(() => {
    axiosClientAuth
      .get('main/node_ip_list')
      .then((res) => {
        console.log(res)
        setIpData(res.data.data)
        // setIpStats(res.data.data.ipaddress_count)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6'>
          <CardsWidget171
            className='h-lg-100 h-100'
            mLables={{
              Whitelisted: ipStats?.approved,
              Blacklisted: ipStats?.rejected,
              Waiting: ipStats?.pending,
            }}
            mColors={{Whitelisted: 'bg-success', Blacklisted: 'bg-danger', Waiting: 'bg-warning'}}
            heading='Vendor Summary'
            subHeading='Vendor'
          />
        </div>

        <div className='col-lg-6'>
          <EngageWidget10 className='h-md-100 bg-primary' />
        </div>
      </div> */}

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12'>
          <TablesWidgetIPLogs className='card-xxl-stretch mb-5 mb-xl-10' ipData={ipData} />
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const IPListWrapper: FC = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('iplist')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>{intl.formatMessage({id: 'Vendor List'})}</PageTitle>
      <IPList />
    </>
  )
}

export {IPListWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  className: string
  data?: any
}

const ListsWidgetExistingLogs = ({ className, data }: Props) => {

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <h3 className='card-title text-gray-800 fw-bold'>SMS Packs details</h3>
        <span className='text-gray-400 fw-semibold fs-6'>Total active packs</span>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Starter</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{data?.Starter}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center mt-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-danger me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Essential</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{data?.Essential}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Standard</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{data?.Standard}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-danger me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Advanced</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{data?.Advanced}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Enterprise</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{data?.Enterprise}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ListsWidgetExistingLogs }
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { KTIcon } from '../../../../helpers'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import moment from 'moment'
import { axiosClientAuth } from '../../../../../app/utils'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { DatePicker } from 'antd'

type Props = {
  className: string
}

type columnType = {
  name: string
  pe_id: string
  user_subscriptions: Array<any>
  created_at: string
  is_activated: boolean
  uuid: string
}

const TablesWidgetOperatorList: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate()

  const [peData, setPeData] = useState([
  ])

  useEffect(() => {
    axiosClientAuth
      .get('/auth/pe-user')
      .then((res) => {
        if (res.data.status) {
          setPeData(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'PE DETAILS',
        accessor: 'name',
        Cell: ({ ...props }) => (
          <>
            <span>{props.value}</span>
            <span className='d-block text-muted fw-semibold'>{props.row.original.pe_id}</span>
          </>
        ),
      },
      // {
      //   Header: 'PLAN',
      //   accessor: 'user_subscription',
      //   Cell: ({ ...props }) => {
      //     return props.value ? (
      //       <span className='badge badge-light-success fw-bold px-4 py-3'>
      //         {props.value}
      //       </span>
      //     ) : (
      //       <span className='badge badge-light-danger fw-bold px-4 py-3'>No Active Plan</span>
      //     )
      //   },
      // },
      {
        Header: 'CREATED AT',
        accessor: 'created_at',
      },
      {
        Header: 'STATUS',
        accessor: 'is_activated',
        Cell: ({ ...props }) => {
          return (
            <>
              <span
                className={`badge badge-light-${props.value == true ? 'success' : 'danger'
                  } fw-bold px-4 py-3`}
              >
                {props.value ? 'Active' : 'Not active'}
              </span>
            </>
          )
        },
      },
      {
        Header: 'ACTION',
        Cell: ({ ...props }) => {
          return (
            <>
              <a
                href='javascript:void(0)'
                className='btn h-35px w-65px btn-icon btn-light me-3'
                onClick={() => navigate(`/operatorsummary?user=${props.row.original.uuid}`)}
              >
                View
              </a>
            </>
          )
        },
      },
    ],
    [peData]
  )

  const operatorListTable = useTable(
    {
      columns,
      data: peData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { RangePicker } = DatePicker

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Account List</span>
          <span className='text-muted fw-semibold fs-7'>Total {peData?.length} account</span>
        </h3>
        <div className='d-flex align-items-center'>
          <select
            className='form-select form-select-sm form-select-solid'
            value={operatorListTable.headers[1].filterValue}
            onChange={(e) => operatorListTable.headers[1].setFilter(e.target.value)}
          >
            {['All', 'Starter', 'Essential', 'Standard', 'Advanced', 'Enterprise'].map((item) => (
              <option value={item == 'All' ? '' : item}>{item}</option>
            ))}
          </select>
          {/* <RangePicker className='bg-gray-100 border-0 text-white me-2' style={{height: '35px'}} /> */}
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Operator'
              value={operatorListTable.state.globalFilter}
              onChange={(e) => operatorListTable.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...operatorListTable.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {operatorListTable.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...operatorListTable.getTableBodyProps()}>
              {(operatorListTable.page.length > 0 &&
                operatorListTable.page.map((row) => {
                  operatorListTable.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={operatorListTable.state.pageSize}
                onChange={(e) => operatorListTable.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {operatorListTable.state.pageIndex + 1} of {operatorListTable.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => operatorListTable.previousPage()}
                disabled={!operatorListTable.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => operatorListTable.nextPage()}
                disabled={!operatorListTable.canNextPage}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { TablesWidgetOperatorList }

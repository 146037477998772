/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { KTIcon } from '../../../../helpers'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { DatePicker } from 'antd'
import { axiosClientAuth } from '../../../../../app/utils'
import clsx from 'clsx'

type Props = {
  className: string
  data?: any
}

const tableData = [
  {
    peid: '74735783478',
    orderId: 'ORD_iuyiuy',
    price: '100',
    orderType: 'SMS packs',
    createdOn: new Date(),
  },
];

type columnType = {
  transaction_id: string,
  amount: string,
  paid_for: string,
  created_at: string,
  status: boolean,
}

const TablesWidgetOrders: React.FC<Props> = ({ className, data }) => {
  const [mData, setMData] = useState(data);

  useEffect(() => {
    setMData(data);
  }, [data])

  const columns: any = useMemo(() => [
    {
      Header: 'PE Details',
      Cell: ({ ...props }) => {
        return <>
          <span>{props?.row?.original?.created_by_details?.name}</span>
          <span className='text-muted ds-6 d-block'>{props?.row?.original?.created_by_details?.pe_id}</span>
        </>
      }
    },
    {
      Header: 'DATE',
      accessor: 'created_at',
      Cell: ({ ...props }) => {
        return (
          <>
            <span>{moment(props.value).format('DD MMM YYYY')}</span>
            <span className='d-block text-muted fw-semibold'>{moment(props.value).format('LT')}</span>
          </>
        )
      },
    },
    {
      Header: 'ORDER TYPE',
      accessor: 'paid_for',
      Cell: ({ ...props }) => {
        let orderType: string;

        switch (props.value) {
          case 'add_url':
            orderType = 'URL Registration';
            break;
          case 'url_verification':
            orderType = 'URL Registration'
            break;
          case 'sms_package':
            orderType = 'SMS Pack'
            break;
          case 'user_registration':
            orderType = 'User Registration'
            break;
          default:
            orderType = ''
            break;
        }

        return (
          <>
            <span>
              {orderType}
            </span>
          </>
        )
      },
    },
    {
      Header: 'AMOUNT',
      accessor: 'amount',
      Cell: ({ ...props }) => {
        return (
          <div className={clsx(props.row.original.status ? 'text-success' : 'text-danger')}>
            ₹ {props.value}
          </div>
        )
      }
    },
    { Header: 'TRANSACTION ID', accessor: 'transaction_id' },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: ({ ...props }) => {
        return (
          <>
            <span className={clsx('badge text-white', props.value ? 'bg-success' : 'bg-danger')}>
              {props.value ? 'Success' : 'Failure'}
            </span>
          </>
        )
      },
    },
    {
      Header: 'ACTION',
      Cell: ({ ...props }) => {
        return (
          <a target='__blank' className='btn btn-light me-3' href={props?.row?.original?.invoice_pdf}>
            Invoice <span className="svg-icon svg-icon-primary svg-icon-1x">{/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo3/dist/../src/media/svg/icons/Files/Download.svg*/}<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <path d="M2,13 C2,12.5 2.5,12 3,12 C3.5,12 4,12.5 4,13 C4,13.3333333 4,15 4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 C2,15 2,13.3333333 2,13 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 8.000000) rotate(-180.000000) translate(-12.000000, -8.000000) " x={11} y={1} width={2} height={14} rx={1} />
                <path d="M7.70710678,15.7071068 C7.31658249,16.0976311 6.68341751,16.0976311 6.29289322,15.7071068 C5.90236893,15.3165825 5.90236893,14.6834175 6.29289322,14.2928932 L11.2928932,9.29289322 C11.6689749,8.91681153 12.2736364,8.90091039 12.6689647,9.25670585 L17.6689647,13.7567059 C18.0794748,14.1261649 18.1127532,14.7584547 17.7432941,15.1689647 C17.3738351,15.5794748 16.7415453,15.6127532 16.3310353,15.2432941 L12.0362375,11.3779761 L7.70710678,15.7071068 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000004, 12.499999) rotate(-180.000000) translate(-12.000004, -12.499999) " />
              </g>
            </svg>{/*end::Svg Icon*/}</span>

          </a>
        )
      },
    },
    {
      Header: 'peid', accessor: 'peid', show: false,
    }
  ], []);

  const complaintsLogsTable = useTable(
    {
      columns,
      data: mData,
      initialState: {
        hiddenColumns: columns.map((column: any) => {
          if (column.show === false) return column.accessor || column.id;
        }),
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  // const { RangePicker } = DatePicker;

  // const onDateChange = (_, dateString) => {
  //   // if (dateString?.length > 0 && dateString[0] == '') {
  //   //   setMData(data);
  //   // } else {
  //   //   setMData(data.filter((item) => {
  //   //     const routeDate = moment(new Date(item?.created_at)).format("DD-MM-YYYY");
  //   //     const startDate = moment(new Date(dateString[0])).format("DD-MM-YYYY");
  //   //     const endDate = moment(new Date(dateString[1])).format("DD-MM-YYYY");

  //   //     return routeDate >= startDate && routeDate <= endDate
  //   //   }))
  //   // }
  // }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Payment Report</span>
          <span className='text-muted fw-semibold fs-7'>Total {complaintsLogsTable?.filteredRows?.length} Order</span>
        </h3>
        <div className='d-flex align-items-center'>
          {/* <RangePicker
            className='bg-gray-100 text-white border-0 me-2'
            style={{ height: '34px' }}
            onChange={onDateChange}
          /> */}
          <select
            className='form-select form-select-sm form-select-solid w-auto'
            value={complaintsLogsTable.headers[2].filterValue}
            onChange={(e) => complaintsLogsTable.headers[2].setFilter(e.target.value)}
          >
            {['All Order Type', 'add_url', 'sms_package'].map((item) => (
              <option value={item == 'All Order Type' ? '' : item}>
                {item == 'add_url' ? 'URL Registration' : item == 'sms_package' ? 'SMS Pack' : 'All Order Type'}
              </option>
            ))}
          </select>
          <select
            className='form-select form-select-sm form-select-solid w-auto ms-2'
            value={complaintsLogsTable.headers[5].filterValue}
            onChange={(e) => complaintsLogsTable.headers[5].setFilter(e.target.value)}
          >
            {['All Status', 'true', 'false'].map((item) => (
              <option value={item == 'All Status' ? '' : item}>
                {item == 'true' ? 'Success' : item == 'false' ? 'Failed' : 'All Status'}
              </option>
            ))}
          </select>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Orders'
              value={complaintsLogsTable.state.globalFilter}
              onChange={(e) => complaintsLogsTable.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...complaintsLogsTable.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {complaintsLogsTable.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-gray ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-gray ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...complaintsLogsTable.getTableBodyProps()}>
              {(complaintsLogsTable.page.length > 0 &&
                complaintsLogsTable.page.map((row) => {
                  complaintsLogsTable.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={complaintsLogsTable.state.pageSize}
                onChange={(e) => complaintsLogsTable.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {complaintsLogsTable.state.pageIndex + 1} of {complaintsLogsTable.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => complaintsLogsTable.previousPage()}
                disabled={!complaintsLogsTable.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => complaintsLogsTable.nextPage()}
                disabled={!complaintsLogsTable.canNextPage}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { TablesWidgetOrders }
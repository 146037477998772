/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { KTIcon } from '../../../../helpers'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import moment from 'moment'
import { axiosClientAuth } from '../../../../../app/utils'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import { DatePicker } from 'antd'
import { useAuth } from '../../../../../app/modules/auth'

type Props = {
  className: string
}

type columnType = {
  ip: string,
  created_at: string,
  status: string,
  uuid: string
}

const TablesWidgetBlacklistIP: React.FC<Props> = ({ className }) => {
  const [ipData, setIpData] = useState([]);
  const [ipStats, setIpStats] = useState({
    blacklisted: 0
  });
  const { checkPermission } = useAuth();

  const [pointerToFetchAgain, setPointerToFetchAgain] = useState(false);

  useEffect(() => {
    axiosClientAuth
      .get('/main/blacklisted-ips')
      .then((res) => {
        console.log(res)
        if (res.data.status) {
          setIpData(res.data.data.blacklisted_ips);
          setIpStats(res.data.data.blacklisted_ips_count);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [pointerToFetchAgain]);

  const updateUrl = (uuid: string, status: boolean) => axiosClientAuth
    .patch('/main/blacklisted-ips', {
      uuid,
      status
    })
    .then(function (res) {
      console.log(res);
      if (res.data.status) {
        Swal.fire({
          titleText: 'Success!',
          text: 'Vendor Updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
        }).then((isConfirm) => {
          isConfirm && setPointerToFetchAgain(!pointerToFetchAgain);
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  const columns: ReadonlyArray<Column<columnType>> = useMemo(() => [
    { Header: 'Vendor', accessor: 'ip' },
    {
      Header: 'DATE',
      accessor: 'created_at',
      Cell: ({ ...props }) => {
        return (
          <>
            <span>{moment(props.value).format('DD/MM/YYYY')}</span>
            <span className='d-block text-muted fw-semibold'>{moment(props.value).format('LT')}</span>
          </>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: ({ ...props }) => {
        return props.value ? (
          <span className='badge badge-light-danger fw-bold px-4 py-3'>blacklist</span>
        ) : (
          <span className='badge badge-light-success fw-bold px-4 py-3'>whitelist</span>
        )
      },
    },
    {
      Header: 'ACTION',
      Cell: ({ ...props }) => {
        const status = props.row.values.status;
        const uuid = props.row.original.uuid;
        return (
          <>
            {
              checkPermission("Global Blacklist", "can_edit") && <>
                <a
                  className={clsx('btn h-35px btn-icon btn btn-light-primary me-3', !status && 'btn-secondary disabled')}
                  onClick={() => updateUrl(uuid, false)}
                >
                  <KTIcon iconName='check' className={clsx('text-hover-white fs-1', !status ? 'text-gray' : 'text-primary')} />
                </a>
                <a
                  className={clsx('btn h-35px btn-icon btn btn-light-danger me-3', status && 'btn-secondary disabled')}
                  onClick={() => updateUrl(uuid, true)}
                >
                  <KTIcon iconName='cross' className={clsx('text-hover-white fs-1', status ? 'text-gray' : 'text-danger')} />
                </a>
              </>
            }
          </>
        )
      },
    },
  ], [ipData])

  const ipTable = useTable(
    {
      columns,
      data: ipData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { RangePicker } = DatePicker;

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Blacklist Vendor Logs</span>
          <span className='text-muted fw-semibold fs-7'>Total {ipStats?.blacklisted} Vendor blocked</span>
        </h3>
        <div className='d-flex align-items-center'>
          <RangePicker className='bg-gray-100 text-white border-0 me-2' style={{ height: '34px' }} />
          <select
            className='form-select form-select-sm form-select-solid w-auto'
            value={ipTable.headers[3].filterValue}
            onChange={(e) => ipTable.headers[3].setFilter(e.target.value)}
          >
            {['All', 'whitelist', 'blacklist'].map((item) => (
              <option value={item == 'All' ? '' : item}>{item}</option>
            ))}
          </select>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Vendor'
              value={ipTable.state.globalFilter}
              onChange={(e) => ipTable.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...ipTable.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {ipTable.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...ipTable.getTableBodyProps()}>
              {(ipTable.page.length > 0 &&
                ipTable.page.map((row) => {
                  ipTable.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={ipTable.state.pageSize}
                onChange={(e) => ipTable.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {ipTable.state.pageIndex + 1} of {ipTable.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => ipTable.previousPage()}
                disabled={!ipTable.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => ipTable.nextPage()}
                disabled={!ipTable.canNextPage}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { TablesWidgetBlacklistIP }
import React, {useState} from 'react'
import {axiosClientAuth} from '../../../utils'
import Swal from 'sweetalert2'

const MyProfileSettings = ({data}) => {
  const [input, setInput] = useState({
    name: data?.name || '',
    phone: data?.phone || '',
  })

  const onSubmitFormProfile = (e: any) => {
    e.preventDefault()

    axiosClientAuth
      .patch('/auth/user-profile', input)
      .then(() => {
        Swal.fire({
          titleText: 'Success!',
          text: 'Profile Details Updated Successfully.',
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        }).then((isConfirm) => {
          if (isConfirm) {
            window.location.reload()
          }
        })
      })
      .catch(() => {
        Swal.fire({
          titleText: 'Error!',
          text: 'Internal Server Issue!!!',
          icon: 'error',
          confirmButtonText: 'Ok! Got It',
          timer: 2000,
          timerProgressBar: true,
        })
      })
  }

  const onChangeProfileHand = (e: any) => {
    setInput((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
        </div>
        <div id='kt_account_settings_profile_details' className='collapse show'>
          <form
            id='kt_account_profile_details_form'
            onSubmit={onSubmitFormProfile}
            className='form'
          >
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                  Full Name
                </label>
                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    name='name'
                    placeholder='Full name'
                    value={input.name}
                    onChange={onChangeProfileHand}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Contact</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    name='phone'
                    placeholder='Phone number'
                    value={input.phone}
                    onChange={onChangeProfileHand}
                    maxLength={10}
                  />
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='reset' className='btn btn-light btn-active-light-primary me-2'>
                Discard
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default MyProfileSettings

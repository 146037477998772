/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {CardsWidget20, ListsWidgetHighlight} from '../../../_metronic/partials/widgets'
import {ChartsWidgetSMSStats} from '../../../_metronic/partials/widgets/_new/charts/ChartsWidgetSMSStats'
import {axiosClientAuth} from '../../utils'

const SMSReport: FC = () => {
  const { peIdSmsReport, dateFilterSmsReport } = useLayout();
  const [smsStats, setSmsStats] = useState({
    total: 0,
    successful: 0,
    failed: 0,
    chart: {
      delta: [],
      success: [],
      failed: [],
    },
  })

  useEffect(() => {
    if(peIdSmsReport && 
      (dateFilterSmsReport?.length > 0 && dateFilterSmsReport[0] != '')){
      axiosClientAuth
        .get(`/main/get-message-analytics?pe_id=${peIdSmsReport}&date_from=${dateFilterSmsReport[0]}&date_to=${dateFilterSmsReport[1]}`)
        .then((res) => {
          if (res.data.status) {
            setSmsStats(res.data.data)
          }
        })
        .catch((err) => console.log(err))
    } else if((dateFilterSmsReport?.length > 0 && dateFilterSmsReport[0] != '')) {
      axiosClientAuth
        .get(`/main/get-message-analytics?date_from=${dateFilterSmsReport[0]}&date_to=${dateFilterSmsReport[1]}`)
        .then((res) => {
          if (res.data.status) {
            setSmsStats(res.data.data)
          }
        })
        .catch((err) => console.log(err))
    } else if(peIdSmsReport) {
      axiosClientAuth
        .get(`/main/get-message-analytics?pe_id=${peIdSmsReport}`)
        .then((res) => {
          if (res.data.status) {
            setSmsStats(res.data.data)
          }
        })
        .catch((err) => console.log(err))
    } else {
      axiosClientAuth
        .get('/main/get-message-analytics')
        .then((res) => {
          if (res.data.status) {
            setSmsStats(res.data.data)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [peIdSmsReport, dateFilterSmsReport])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Number of SMS Submitted'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            total={smsStats?.total}
            failed={smsStats?.failed}
          />
          <ListsWidgetHighlight
            className='h-lg-50'
            successful={smsStats?.successful}
            unsuccessful={smsStats?.failed}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-9'>
          <ChartsWidgetSMSStats
            className='h-100 mb-xl-8'
            chartData={smsStats?.chart}
            total={smsStats?.total}
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SMSReportWrapper: FC = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('smsreport')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>{intl.formatMessage({id: 'SMS Report'})}</PageTitle>
      <SMSReport />
    </>
  )
}

export {SMSReportWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'
import { CardsWidget17, EngageWidget10, StatisticsWidget3 } from '../../../_metronic/partials/widgets'
import { TablesWidgetURLScrubbingLogs } from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetURLScrubbingLogs'
import { axiosClientAuth } from '../../utils'
import { ListsWidgetExistingLogs } from '../../../_metronic/partials/widgets/_new/lists/ListsWidgetExistingLogs'
import { StatisticsWidgetNewCustomer } from '../../../_metronic/partials/widgets/statistics/StatisticsWidgetNewCustomer'
import StatisticsWidgetClient from '../../../_metronic/partials/widgets/statistics/StatisicsWidgetClient'
import CardsDashboardMain from '../../../_metronic/partials/widgets/_new/cards/CardsDashboardMain'

const DashboardPage: FC = () => {
  const [dashboardData, setDashboardData] = useState<any>(null)
  const { setLayoutType, dateFilterDashboard } = useLayout()

  useEffect(() => {
    axiosClientAuth
      .get(
        `/main/admin-dashboard?start_date=${dateFilterDashboard[0]}&end_date=${dateFilterDashboard[1]}`
      )
      .then((resp) => {
        setDashboardData(resp.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [dateFilterDashboard])

  useEffect(() => {
    setLayoutType('dark-header')
  }, [])

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
          {/* <CardsWidget20
            className='card-xl-stretch mb-5 mb-xl-10'
            description='Invoice Generated'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            total={dashboardData?.invoice_generated}
            failed={0}
          /> */}
          <CardsDashboardMain data={dashboardData} className='mb-5 mb-xl-10' />
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <CardsWidget17 data={dashboardData?.revenue} className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
          <StatisticsWidget3
            className='card-xl-stretch mb-xl-8'
            color='success'
            title={dashboardData?.revenue_per_month?.length > 0 ? dashboardData?.revenue_per_month[dashboardData?.revenue_per_month?.length - 1] : ''}
            description='Monthly Sales'
            change='+2.4%'
            chartData={dashboardData?.revenue_per_month || []}
          />
        </div>
        <div className='col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <StatisticsWidgetNewCustomer
            className='card-xl-stretch mb-xl-8'
            color='white'
            pending={dashboardData?.url_count?.pending}
            total={dashboardData?.url_count?.total}
          />
        </div>

        {/* <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-0'>
          <ListsWidgetExistingLogs
            data={dashboardData?.sms_package_count}
            className='h-100 mb-5 mb-xl-10'
          />
        </div> */}
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-0'>
          <StatisticsWidgetClient data={dashboardData} className='h-100 mb-5 mb-xl-10' />
        </div>
        <div className='col-md-8 col-lg-8 col-xl-8 col-xxl-8 mt-0'>
          <EngageWidget10 data={dashboardData?.open_tickets} className='h-md-100 bg-primary' />
        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12'>
          {dashboardData?.recent_urls && <TablesWidgetURLScrubbingLogs
            title="Recent URLs"
            subtitle="Recent : "
            filter={false}
            className='card-xxl-stretch mb-5 mb-xl-10'
            urlData={dashboardData?.recent_urls}
          />}
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const { setRightTolbar } = useLayout()

  useEffect(() => {
    setRightTolbar('dashboard')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }

import React, {useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {axiosClientAuth} from '../../utils'
import {DatePicker} from 'antd'
import {useLayout} from '../../../_metronic/layout/core'

const loginSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Minimum 3 symbols').required('PE ID is required'),
  url: Yup.string().min(3, 'Minimum 3 symbols').required('PE ID is required'),
})

const initialValues = {
  name: '',
  url: '',
  trust: false,
}

function DashboardRightToolbar() {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const resp = await axiosClientAuth.post('/main/user-urls', {
          name: values.name,
          url: values.url,
          is_trusted: values.trust,
        })
        console.log(resp.data)
        setLoading(false)
        // console.log(auth)
      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        // setStatus('The login details are incorrect')
        // setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const {RangePicker} = DatePicker
  const {setDateFilterDashboard} = useLayout()

  return (
    <>
      <RangePicker
        className='bg-secondary text-white border-0 me-2'
        style={{height: '40px'}}
        onChange={(value, dateString) => setDateFilterDashboard(dateString)}
      />

      {/* <button
        className='btn btn-sm fw-bold btn-primary'
        onClick={() => {
          setShow(!show)
        }}
      >
        URL Registration
      </button> */}

      {/* My Modal */}
      <Modal
        className='modal-sticky modal-ld modal-sticky-lg modal-sticky-bottom-right'
        id='kt_inbox_compose'
        role='dialog'
        data-backdrop='false'
        aria-hidden='true'
        tabIndex='-1'
        show={show}
        animation={true}
        centered={true}
      >
        {/* <div className='modal-header'>
          
        </div> */}
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='d-flex justify-content-end'>
            <button
              className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setShow(false)}
            >
              <KTIcon iconName='cross' className='text-white fs-1' />
            </button>
          </div>

          <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>Register URL</h1>
              <div className='text-muted fw-semibold fs-5'>
                If you need more info, please check{' '}
                <a href='#' className='fw-bold link-primary'>
                  Our Guidelines
                </a>
                .
              </div>
            </div>

            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : null}

            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark required'>URL Title</label>
              <input
                placeholder='Enter URL Title'
                {...formik.getFieldProps('name')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                type='text'
                name='name'
                autoComplete='off'
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              )}
            </div>

            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark required'>URL</label>
              <input
                placeholder='Enter URL'
                {...formik.getFieldProps('url')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.url && formik.errors.url},
                  {
                    'is-valid': formik.touched.url && !formik.errors.url,
                  }
                )}
                type='text'
                name='url'
                autoComplete='off'
              />
              {formik.touched.url && formik.errors.url && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.url}</span>
                </div>
              )}
            </div>

            <div className='fv-row mb-8'>
              <input
                {...formik.getFieldProps('peid')}
                className={clsx(
                  'form-check-input bg-transparent',
                  {'is-invalid': formik.touched.trust && formik.errors.trust},
                  {
                    'is-valid': formik.touched.trust && !formik.errors.trust,
                  }
                )}
                type='checkbox'
                name='trust'
                autoComplete='off'
              />
              <label className='form-check-label fw-semibold text-gray-700 fs-base ms-1'>
                Trusted URL
              </label>
              {formik.touched.trust && formik.errors.trust && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.trust}</span>
                </div>
              )}
            </div>

            {/* begin::Action */}
            <div className='text-center mb-10'>
              <button type='reset' className='btn btn-light me-3' onClick={() => setShow(false)}>
                <span className='indicator-label'>Cancel</span>
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default DashboardRightToolbar

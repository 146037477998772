import React, { useEffect, useState } from 'react'
import { PageTitle, useLayout } from '../../../_metronic/layout/core'
import { CardsWidget171 } from '../../../_metronic/partials/widgets/_new/cards/CardsWidget171'
import { TablesWidgetURLScrubbingLogs } from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetURLScrubbingLogs'
import { axiosClientAuth } from '../../utils'
import { EngageWidget10 } from '../../../_metronic/partials/widgets'

const myBreadCrumbs: any = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export default function UrlVerification() {
  const { setRightTolbar } = useLayout()
  const [urlData, setUrlData] = useState<any>({})
  const [pointerToFetchAgain, setPointerToFetchAgain] = useState(false)

  useEffect(() => {
    setRightTolbar('')
  }, [])

  useEffect(() => {
    axiosClientAuth
      .get('/main/urls')
      .then((res) => {
        setUrlData(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [pointerToFetchAgain])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>URL Verification</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-12 col-lg-12 col-xl-6'>
          {
            urlData?.urls &&
            <CardsWidget171
              className='h-lg-100 h-100'
              mLables={{
                Whitelist: urlData?.url_count?.whitelisted,
                Blacklist: urlData?.url_count?.blacklisted,
                Pending: urlData?.url_count?.pending,
              }}
              mColors={{ Whitelist: 'bg-success', Blacklist: 'bg-danger', Pending: 'bg-warning' }}
              heading='URL Summary'
              subHeading='URLs'
            />
          }
        </div>

        <div className='col-md-12 col-lg-12 col-xl-6'>
          <EngageWidget10 openTickets={false} data={0} className='h-md-100 bg-primary' />
        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12'>
          {urlData?.urls && <TablesWidgetURLScrubbingLogs
            className='card-xxl-stretch mb-5 mb-xl-10'
            urlData={urlData?.urls}
          />}
        </div>
      </div>
    </>
  )
}
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../helpers'
import {axiosClientAuth} from '../../../../../app/utils'
import Swal from 'sweetalert2'

const ModalViewRole = ({show, setShow, curr}) => {
  const [roleName, setRoleName] = useState(curr?.name)
  const [roleDescription, setRoleDescription] = useState(curr?.description)
  const [permissions, setPermissons] = useState([])

  const getData = async () => {
    try {
      const {data: mData} = await axiosClientAuth.get('/auth/permissions')
      console.log(mData)
      setPermissons(
        mData.data.map((ele) => {
          const existing = curr?.group_permissions?.filter(
            (item) => item?.permission?.uuid == ele?.uuid
          )
          return existing.length
            ? {
                ...ele,
                can_read: existing[0]?.can_read,
                can_write: existing[0]?.can_write,
                can_edit: existing[0]?.can_edit,
              }
            : {...ele, can_read: false, can_write: false, can_edit: false}
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setRoleName(curr?.name)
    setRoleDescription(curr?.description)
    getData()
  }, [curr])

  return (
    <Modal
      className='modal-sticky modal-lg modal-sticky-lg modal-sticky-bottom-right'
      show={show}
      centered={true}
    >
      <div className='modal-header'>
        <h2>View role</h2>

        <div className='d-flex justify-content-end'>
          <button
            className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setShow(false)}
          >
            <KTIcon iconName='cross' className='text-gray fs-1' />
          </button>
        </div>
      </div>

      <div className='modal-body py-lg-0 px-lg-0'>
        <div className='modal-body scroll-y mx-lg-5 my-7'>
          <form className='form'>
            <div className='d-flex flex-column scroll-y me-n7 pe-7'>
              <div className='fv-row mb-10'>
                <label className='fs-5 fw-bold form-label mb-2'>
                  <span className='required'>Role name</span>
                </label>
                <input
                  className='form-control form-control-solid'
                  placeholder='Enter a role name'
                  name='role_name'
                  value={roleName}
                  disabled
                />
              </div>
              <div className='fv-row mb-10'>
                <label className='fs-5 fw-bold form-label mb-2'>
                  <span className='required'>Role description</span>
                </label>
                <input
                  className='form-control form-control-solid'
                  placeholder='Enter a role description'
                  name='description'
                  value={roleDescription}
                  disabled
                />
              </div>
              <div className='fv-row'>
                <label className='fs-5 fw-bold form-label mb-2'>Role Permissions</label>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-dashed fs-6 gy-5'>
                    <tbody className='text-gray-600 fw-semibold'>
                      <tr>
                        <td className='text-gray-800'>
                          Administrator Access
                          <i
                            className='fas fa-exclamation-circle ms-1 fs-7'
                            data-bs-toggle='tooltip'
                            title='Allows a full access to the system'
                          />
                        </td>
                        <td>
                          <label className='form-check form-check-custom form-check-solid me-9'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              defaultValue=''
                              id='kt_roles_select_all'
                              disabled
                            />
                            <span
                              className='form-check-label'
                              // htmlFor="kt_roles_select_all"
                            >
                              Select all
                            </span>
                          </label>
                        </td>
                      </tr>
                      {permissions?.map((permission: any) => (
                        <tr key={permission.uuid}>
                          <td className='text-gray-800'>{permission.name}</td>
                          <td>
                            <div className='d-flex'>
                              <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='user_management_read'
                                  checked={permission.can_read}
                                />
                                <span className='form-check-label'>Read</span>
                              </label>
                              <label className='form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='user_management_create'
                                  checked={permission.can_write}
                                />
                                <span className='form-check-label'>Create</span>
                              </label>
                              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  name='user_management_modify'
                                  checked={permission.can_edit}
                                />
                                <span className='form-check-label'>Modify</span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='text-center pt-15'>
              <button type='reset' className='btn btn-light me-3' onClick={() => setShow(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default ModalViewRole

import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useAuth } from '../../../../../app/modules/auth'

export function MenuInner() {
  const intl = useIntl()
  const { auth } = useAuth()

  return (
    <>
      {auth?.user?.group_obj?.group_permissions.filter((ele) => ele.permission.name == 'Dashboard')
        .length > 0 && <MenuItem title='Dashboard' to='/dashboard' />}
      {auth?.user?.group_obj?.group_permissions.filter(
        (ele) => ele.permission.name == 'URL Verification'
      ).length > 0 && <MenuItem title='URL Verification' to='/urlverification' />}
      {auth?.user?.group_obj?.group_permissions.filter(
        (ele) => ele.permission.name == 'Mapped URLs'
      ).length > 0 && <MenuItem title='Mapped URLs' to='/managetemplate' />}
      {auth?.user?.group_obj?.group_permissions.filter((ele) => ele.permission.name == 'SMS Report')
        .length > 0 && <MenuItem title='Message Data' to='/smsreport' />}
      {auth?.user?.group_obj?.group_permissions.filter(
        (ele) => ele.permission.name == 'Vendor List'
      ).length > 0 && <MenuItem title='Vendor List' to='/iplist' />}
      {/* {
        auth?.user?.group_obj?.group_permissions.filter(ele => ele.permission.name == "Global Blacklist").length > 0 &&
        <MenuInnerWithSub title='Global Blacklist' to='/blacklist' menuPlacement='bottom-start' menuTrigger='click'>
          <MenuItem to='/blacklisturl' title='URL Blacklist' icon='fasten' />
          <MenuItem to='/blacklistip' title='IP Blacklist' icon='focus' />
        </MenuInnerWithSub>
      } */}
      {auth?.user?.group_obj?.group_permissions.filter(
        (ele) => ele.permission.name == 'Account Statistics'
      ).length > 0 && <MenuItem title='Account Statistics' to='/operatordata' />}
      {auth?.user?.group_obj?.group_permissions.filter(
        (ele) =>
          ele.permission.name == 'User Management' || ele.permission.name == 'Roles Management'
      ).length > 0 && (
          <MenuInnerWithSub
            title='Account Control'
            to='/account'
            menuPlacement='bottom-start'
            menuTrigger='click'
          >
            {auth?.user?.group_obj?.group_permissions.filter(
              (ele) => ele.permission.name == 'User Management'
            ).length > 0 && (
                <MenuItem to='/usermanagement' title='User Management' icon='profile-user' />
              )}
            {auth?.user?.group_obj?.group_permissions.filter(
              (ele) => ele.permission.name == 'Roles Management'
            ).length > 0 && (
                <MenuItem to='/rolesmanagement' title='Roles Management' icon='burger-menu-3' />
              )}
          </MenuInnerWithSub>
        )}
      {auth?.user?.group_obj?.group_permissions.filter(
        (ele) => ele.permission.name == 'Support Center'
      ).length > 0 && <MenuItem title='Tickets' to='/support' />}
    </>
  )
}

import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../_metronic/helpers'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { CardsWidgetPlanDetails } from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetPlanDetails'
import { CardsWidgetSMSDetails } from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetSMSDetails'
import { ListsWidgetURLHighlight } from '../../../_metronic/partials/widgets/_new/lists/ListWidgetURLHighlight'
import { ListsWidgetIPHighlight } from '../../../_metronic/partials/widgets/_new/lists/ListWidgetIPHighlight'
import { useSearchParams } from 'react-router-dom'
import { axiosClient, axiosClientAuth } from '../../utils'
import axios from 'axios'

const operatorData = [
  {
    name: 'Airtel',
    imgSrc: '/media/operator/airtel.svg',
    smsSent: '30%',
    color: 'primary'
  },
  {
    name: 'Vodafone Idea',
    imgSrc: '/media/operator/vi.svg',
    smsSent: '50%',
    color: 'secondary'
  },
  {
    name: 'BSNL',
    imgSrc: '/media/operator/bsnl.png',
    smsSent: '89%',
    color: 'success'
  },
  {
    name: 'Jio',
    imgSrc: '/media/operator/jio.png',
    smsSent: '23%',
    color: 'danger'
  },
  {
    name: 'MTNL',
    imgSrc: '/media/operator/mtnl.svg',
    smsSent: '63%',
    color: 'info'
  },
  {
    name: 'Reliance',
    imgSrc: '/media/operator/reliance.svg',
    smsSent: '34%',
    color: 'danger'
  },
  {
    name: 'Tata Teleservice',
    imgSrc: '/media/operator/tata.png',
    smsSent: '2%',
    color: 'warning'
  },
  {
    name: 'QTEL',
    imgSrc: '/media/operator/hfcl.webp',
    smsSent: '47%',
    color: 'dark'
  },
]

const OperatorSummary = () => {
  const [searchParams] = useSearchParams()
  const [userData, setUserData] = useState<any>([])
  const [totalsms, setTotalsms] = useState(0);
  const [usedsms, setUsedsms] = useState(0);
  const [operators, setOperators] = useState<any>([]);

  const [show, setShow] = useState(false)

  useEffect(() => {
    axiosClientAuth
      .get(`auth/pe-user?uuid=${searchParams.get('user')}`)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => console.log(err))
    axiosClientAuth
      .get(`auth/get-operators`)
      .then((res) => {
        setOperators(res.data.data);
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    let tempTotalsms = 0;
    let tempUsedsms = 0;
    userData?.user_subscriptions?.forEach(pack => {
      tempTotalsms += pack?.sms_count;
      tempUsedsms += pack?.used_sms;
    });
    console.log(userData?.user_subscriptions);
    setTotalsms(tempTotalsms);
    setUsedsms(tempUsedsms);
  }, [userData]);

  const onClickLogin = (op) => {
    if (op.name == 'QTEL') {
      const mData = {
        "pe_uuid": userData?.uuid,
        "operator_uuid": operators[0].uuid,
      }
      axiosClientAuth.post("/auth/get-cross-auth-token", mData).then((response) => {
        const { cross_auth_token, pe_id } = response?.data?.data;
        const mData2 = {
          cross_auth_token,
          pe_id
        }
        axiosClient.post(response?.data?.data?.redirect_url, mData2, { headers: { Authorization: '' } }).then((response1) => {
          console.log(response1.data);
          window.open("https://otp-pe.goflipo.com/loginwithtoken?token=" + response1?.data?.data?.access_token, '_blank');
        }).catch((error) => {
          console.error(error);
        });
      }).catch((error) => {
        console.error(error);
      });
    }
  }

  return (
    <>
      <div className='d-flex justify-content-end mb-4 align-items-center'>
        <button
          className='btn btn-primary px-20'
          onClick={() => setShow(true)}
        >
          Login As PE
        </button>
      </div>
      <div className='row g-5 g-xl-10 mb-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidgetPlanDetails
            className='h-100 mb-5 mb-xl-10'
            description='Plan Details'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            data={userData}
            totalsms={totalsms}
            usedsms={usedsms}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidgetSMSDetails data={userData} className='h-100 mb-5 mb-xl-10' />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-10'>
          <ListsWidgetURLHighlight data={userData} className='h-100 mb-5 mb-xl-10' />
        </div>
        {/* <ChartsWidget1 className='' /> */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <ListsWidgetIPHighlight className='h-100 mb-5 mb-xl-10' />
        </div> */}
      </div>

      <Modal
        show={show}
        className="modal fade modal-lg"
      >
        {/* <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content"> */}
        <div className="modal-header">
          <h2 className="fw-bold">Select Operator</h2>
          <div className='d-flex justify-content-end'>
            <button
              className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setShow(false)}
            >
              <KTIcon iconName='cross' className='text-gray fs-1' />
            </button>
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <div className='row'>
            {operatorData?.map((op, index) => {
              return (
                <div className='col-md-3 mb-5'>
                  <div className="card hover-scale cursor-pointer h-100 p-3" onClick={() => onClickLogin(op)}>
                    <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                      <img
                        src={toAbsoluteUrl(op.imgSrc)}
                        className="w-30px h-30px"
                        style={{ objectFit: "contain" }}
                        alt=""
                      />
                      <div className="flex-grow-1">
                        <span
                          className="text-gray-800 fs-5 fw-bold lh-0"
                        >
                          {op.name}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* {index != operatorData.length - 1 && <div className="separator separator-dashed my-3" />} */}
                </div>
              )
            })}
          </div>
        </div>
        {/* </div>
        </div> */}
      </Modal>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Account Statistics',
    path: '/operatordata',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OperatorSummaryWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {/* {intl.formatMessage({ id: 'Account Summary' })} */}
        Account Summary
      </PageTitle>
      <OperatorSummary />
    </>
  )
}

export default OperatorSummaryWrapper

import React, { useEffect, useState } from 'react'
import { KTIcon, KTSVG } from '../../../../_metronic/helpers'
import { ChatInner } from '../../../../_metronic/partials'
import { axiosClientAuth } from '../../../utils'
import { Card1 } from '../../../../_metronic/partials/content/cards/Card1'
import moment from 'moment'

const Tickets = () => {
  const [data, setData] = useState([])
  const [activeTicket, setActiveTicket] = useState<any>(null)
  const [ticketChats, setTicketChats] = useState<any>([])

  useEffect(() => {
    axiosClientAuth
      .get('/support/admin-support-ticket')
      .then((resp) => setData(resp.data.data?.data))
      .catch(() => { })
  }, [])

  return (
    <div className='card card-flush g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='card-body'>
        <div className='row g-6 g-xl-9'>
          {data?.map((item: any, index: number) => (
            <div className='col-md-4 col-xxl-4'>
              <Card1
                avatar='/media/avatars/300-6.jpg'
                name={item.title}
                job={item.description}
                status={item.status}
                totalEarnings='$236,400'
                item={item}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Tickets

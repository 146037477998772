import React, { useState } from 'react'
import { DatePicker } from 'antd'
import { KTIcon } from '../../../_metronic/helpers';
import { useLayout } from '../../../_metronic/layout/core';

const ManageTemplateRightToolbar = () => {
  const { RangePicker } = DatePicker;

  const {
    setManageTemplateDateFilter,
    listOfPeidForManageTemplate,
    manageTemplatePeidFilter,
    setManageTemplatePeidFilter
  } = useLayout();

  return (
    <>
      <div className='d-flex align-items-center'>
        <RangePicker
          className='bg-secondary text-white border-0 me-2'
          style={{ height: '40px' }}
          onChange={(value, dateString) => setManageTemplateDateFilter(dateString)}
        />

        <select
          value={manageTemplatePeidFilter}
          className='bg-secondary w-auto ms-3 px-1 border-0' style={{ height: '40px', borderRadius: '6px' }}
          onChange={(e: any) => setManageTemplatePeidFilter(e.target.value)}
        >
          {['PE ID', ...listOfPeidForManageTemplate].map((item) => (
            <option
              value={item == 'PE ID' ? '' : item}
            >
              {item}
            </option>
          ))}
        </select>

        {manageTemplatePeidFilter
          ? <button
            className='btn h-40px btn-danger ms-3 d-flex align-items-center'
            onClick={() => setManageTemplatePeidFilter('')}
          >
            Clear
            <KTIcon iconName='cross' className='text-white fs-1 ms-5' />
          </button>
          : <></>
        }
      </div>
    </>
  )
}

export default ManageTemplateRightToolbar
import React, { useState } from 'react'
import { KTSVG } from '../../../../helpers'
import { tabs } from '../../../../../app/pages/supportCenter/Support'

const EngageWidgetSupport = ({ tab, setTab }) => {
  return (
    <div className='card mb-12'>
      <div className='card-body flex-column p-5'>
        <div className='card-rounded bg-light d-flex flex-stack flex-wrap p-5'>
          <ul className='nav flex-wrap border-transparent fw-bold'>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${tab == tabs.overview && 'active'}`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.overview)}
              >
                Overview
              </a>
            </li>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${tab == tabs.petickets && 'active'}`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.petickets)}
              >
                PE tickets
              </a>
            </li>
            {/* <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${tab == tabs.admintickets && 'active'}`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.admintickets)}
              >
                Admin tickets
              </a>
            </li>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${tab == tabs.query && 'active'}`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.query)}
              >
                Queries
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default EngageWidgetSupport

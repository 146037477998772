import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { KTIcon } from '../../../_metronic/helpers';
import { axiosClientAuth } from '../../utils';
import { useLayout } from '../../../_metronic/layout/core';

const SMSReportRightToolbar = () => {
  const { RangePicker } = DatePicker;
  const [allPE, setAllPE] = useState([]);
  const { peIdSmsReport, setPeIdSmsReport, setDateFilterSmsReport } = useLayout();

  useEffect(() => {
    axiosClientAuth.get("/auth/pe-user").then(resp => {
      setAllPE(resp.data.data.map(ele => ele.pe_id));
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const onChangeDate = (_, datestring) => {
    setDateFilterSmsReport(datestring);
  }

  return (
    <>
      <div className='d-flex align-items-center'>
        <RangePicker
          className='bg-secondary text-white border-0 me-2'
          style={{ height: '40px' }}
          onChange={onChangeDate}
        />

        <select
          className='bg-secondary w-auto ms-3 px-1 border-0'
          style={{ height: '40px', borderRadius: '6px' }}
          value={peIdSmsReport}
          onChange={(e: any) => setPeIdSmsReport(e.target.value)}
        >
          {['PE ID', ...allPE].map((item) => (
            <option value={item == 'PE ID' ? '' : item}>{item}</option>
          ))}
        </select>

        {peIdSmsReport
          ? <button
            className='btn h-40px btn-danger ms-3 d-flex align-items-center'
            onClick={() => setPeIdSmsReport('')}
          >
            Clear
            <KTIcon iconName='cross' className='text-white fs-1 ms-5' />
          </button>
          : <></>
        }
      </div>
    </>
  )
}

export default SMSReportRightToolbar
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'
import EngageWidgetSupport from '../../../_metronic/partials/widgets/_new/engage/EngageWidgetSupport'
import Tickets from '../../modules/apps/support/Tickets'
import FAQ from '../../modules/apps/support/FAQ'
import OverviewTickets from '../../modules/apps/support/OverviewTickets'

export enum tabs {
  'overview' = 'overview',
  'petickets' = 'petickets',
  'admintickets' = 'admintickets',
  'query' = 'query'
}

const SupportCenter: FC = () => {
  const [tab, setTab] = useState(tabs.overview)

  let [tabComponent, setTabComponent] = useState(<OverviewTickets />)

  useEffect(() => {
    switch (tab) {
      case tabs.overview:
        setTabComponent(<OverviewTickets />);
        break;
      case tabs.petickets:
        setTabComponent(<Tickets />);
        break;
      // case tabs.admintickets:
      //   setTabComponent(<Tickets />);
      //   break;
      // case tabs.query:
      //   setTabComponent(<Tickets />);
      //   break;
    }
  }, [tab])

  return (
    <>
      <EngageWidgetSupport tab={tab} setTab={setTab} />
      {tabComponent}
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/support',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SupportCenterWrapper: FC = () => {
  const intl = useIntl()
  const { setRightTolbar } = useLayout()

  useEffect(() => {
    setRightTolbar('Support Center')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({ id: 'Support Center' })}
      </PageTitle>
      <SupportCenter />
    </>
  )
}

export { SupportCenterWrapper }
import { toAbsoluteUrl } from "../../../../helpers";

export default function CardsDashboardMain({ className, data }) {
  return (
    <div className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`} style={{
      backgroundColor: '#F1416C',
      backgroundImage: `url('${toAbsoluteUrl('/media/patterns/vector-1.png')}')`,
    }}>
      <div className='card-header pt-5'>
        <h3 className='card-title text-white fw-bold'>Highlights</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-4'>
        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='text-white flex-grow-1 me-4'>Invoice Generated</div>
            {/* <span className="svg-icon svg-icon-2 svg-icon-success me-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="16.9497" y="8.46448" width="13" height="2" rx="1" transform="rotate(135 16.9497 8.46448)" fill="currentColor" />
                <path d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z" fill="currentColor" />
              </svg>
            </span> */}
            <div className='fw-bolder text-white text-xxl-end'>{data?.invoice_generated_count}</div>
          </div>

          <div className="separator separator-dashed my-3"></div>

          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='text-white flex-grow-1 me-4'>Total Vendors</div>
            {/* <span className="svg-icon svg-icon-2 svg-icon-danger me-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="7.05026" y="15.5355" width="13" height="2" rx="1" transform="rotate(-45 7.05026 15.5355)" fill="currentColor" />
                <path d="M9.17158 14.0284L9.17158 8.11091C9.17158 7.52513 8.6967 7.05025 8.11092 7.05025C7.52513 7.05025 7.05026 7.52512 7.05026 8.11091L7.05026 15.9497C7.05026 16.502 7.49797 16.9497 8.05026 16.9497L15.8891 16.9497C16.4749 16.9497 16.9498 16.4749 16.9498 15.8891C16.9498 15.3033 16.4749 14.8284 15.8891 14.8284L9.97158 14.8284C9.52975 14.8284 9.17158 14.4703 9.17158 14.0284Z" fill="currentColor" />
              </svg>
            </span> */}
            <div className='fw-bolder text-white text-xxl-end'>{data?.pe_users_count}</div>
          </div>

          <div className="separator separator-dashed my-3"></div>

          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='text-white flex-grow-1 me-4'>Open Tickets</div>
            {/* <span className="svg-icon svg-icon-2 svg-icon-danger me-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="7.05026" y="15.5355" width="13" height="2" rx="1" transform="rotate(-45 7.05026 15.5355)" fill="currentColor" />
                <path d="M9.17158 14.0284L9.17158 8.11091C9.17158 7.52513 8.6967 7.05025 8.11092 7.05025C7.52513 7.05025 7.05026 7.52512 7.05026 8.11091L7.05026 15.9497C7.05026 16.502 7.49797 16.9497 8.05026 16.9497L15.8891 16.9497C16.4749 16.9497 16.9498 16.4749 16.9498 15.8891C16.9498 15.3033 16.4749 14.8284 15.8891 14.8284L9.97158 14.8284C9.52975 14.8284 9.17158 14.4703 9.17158 14.0284Z" fill="currentColor" />
              </svg>
            </span> */}
            <div className='fw-bolder text-white text-xxl-end'>{data?.open_tickets_count}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'

type Props = {
  className: string
  color: string
}

const StatisticsWidgetNewCustomer: React.FC<any> = ({className, color, pending, total}) => {
  return (
    <div className={`card hoverable ${className}`}>
      <div className='card-body d-flex justify-content-between align-items-start flex-column'>
        <div className='m-0'>
          <KTIcon className='fs-2hx' iconName='fasten' />
        </div>
        <div className='d-flex flex-column my-7'>
          <span className='fw-semibold fs-3x text-gray-800 lh-1 ls-n2'>{total}</span>
          <div className='m-0'>
            <span className='fw-semibold fs-6 text-gray-400'>Total URLs</span>
          </div>
        </div>
        <span className='badge badge-light-danger fs-base'>
          {/* <KTIcon className='text-danger me-2' iconName='arrow-down' /> */}
          Pending URLs : {pending}
        </span>
      </div>
    </div>
  )
}

export {StatisticsWidgetNewCustomer}

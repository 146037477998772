/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'
import { TablesWidgetOrders } from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetOrders'
import EngageWidgetMyProfile from '../../../_metronic/partials/widgets/_new/engage/EngageWidgetMyProfile'
import OverviewMyProfile from '../../modules/apps/myprofile/OverviewMyProfile'
import MyProfileSettings from '../../modules/apps/myprofile/MyProfileSettings'
import BillingMyPorfile from '../../modules/apps/myprofile/BillingMyProfile'
import { PaymentReportWrapper } from '../paymentReport/PaymentReport'
import { axiosClientAuth } from '../../utils'
import moment from 'moment'

export enum MyProfileTabs {
  'overview' = 'overview',
  'settings' = 'settings',
  'paymentreport' = 'paymentreport',
}

const MyProfile: FC = () => {
  const [tab, setTab] = useState(MyProfileTabs.overview);

  let [tabComponent, setTabComponent] = useState(<></>);

  const [data, setData] = useState<any>({})

  useEffect(() => {
    axiosClientAuth
      .get('/auth/user-profile')
      .then((res) => setData(res.data?.data))
      .catch((err) => console.log(err))
  }, [])

  const [dashboardData, setDashboardData] = useState<any>(null);
  const [paymentDataOrg, setPaymentDataOrg] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [dateFilter, setDateFilter] = useState([]);

  const getData = useCallback(() => {
    axiosClientAuth.get("/payments/transactions").then(resp => {
      setPaymentData(resp.data.data.map(ele => ({ ...ele, peid: ele?.created_by_details?.name })));
      setPaymentDataOrg(resp.data.data.map(ele => ({ ...ele, peid: ele?.created_by_details?.name })))
    }).catch(err => {
      console.log(err);
    })
  }, [])
  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (dateFilter.length > 0 && dateFilter[0] != '') {
      axiosClientAuth.get(`/main/admin-dashboard?start_date=${dateFilter[0]}&end_date=${dateFilter[1]}`).then(resp => {
        setDashboardData(resp.data.data);
        setPaymentData(paymentDataOrg.filter((item: any) => {
          const routeDate = moment(new Date(item?.created_at)).format("DD-MM-YYYY");
          const startDate = moment(new Date(dateFilter[0])).format("DD-MM-YYYY");
          const endDate = moment(new Date(dateFilter[1])).format("DD-MM-YYYY");

          return routeDate >= startDate && routeDate <= endDate
        }))
      }).catch(err => {
        console.log(err);
      })
    } else {
      axiosClientAuth.get("/main/admin-dashboard").then(resp => {
        setDashboardData(resp.data.data);
        setPaymentData(paymentDataOrg)
      }).catch(err => {
        console.log(err);
      })
    }
  }, [dateFilter])

  return (
    <>
      <EngageWidgetMyProfile tab={tab} setTab={setTab} data={data} />
      {
        paymentData && tab === MyProfileTabs.overview ?
          <OverviewMyProfile data={data} setTab={setTab} />
          : tab === MyProfileTabs.settings ?
            <MyProfileSettings data={data} />
            : <PaymentReportWrapper
              setDateFilter={setDateFilter}
              dashboardData={dashboardData}
              paymentData={paymentData}
            />
      }
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const MyProfileWrapper: FC = () => {
  const intl = useIntl()

  const { setRightTolbar } = useLayout()

  useEffect(() => {
    setRightTolbar('')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>{intl.formatMessage({ id: 'My Profile' })}</PageTitle>
      <MyProfile />
    </>
  )
}

export { MyProfileWrapper }
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'
import {useLayout} from '../../../layout/core'
import moment from 'moment'

const DrawerTicketDetail: FC = () => {
  const {currentTicket} = useLayout()

  return (
    <div
      id='kt_drawer_ticket_detail'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_ticket_detail_toggle'
      data-kt-drawer-close='#kt_drawer_ticket_detail_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_ticket_detail_messenger'>
        <div className='card-header pe-5' id='kt_drawer_ticket_detail_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <h1>Ticket Details</h1>
            </div>
          </div>
          <div className='card-toolbar'>
            {/* <div className='me-2'>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <i className='bi bi-three-dots fs-3'></i>
              </button>
            </div> */}

            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_drawer_ticket_detail_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>
        <div className='card-body'>
          <h2 className='fs-2 text-gray-800 fw-bolder mb-2'>{currentTicket?.title}</h2>
          <h6 className='fw-bold text-gray-500 mb-4'>
            <span>{moment(currentTicket?.created_at).format('DD MMM YYYY LT')}</span>
          </h6>
          <h6 className='fw-bold text-gray-600 mb-4'>{currentTicket?.description}</h6>
          <h6 className='fw-bold text-gray-500 mb-4'>
            <span>Issue Type :</span> {currentTicket?.issue_type}
          </h6>
          {currentTicket?.attachment_url && (
            <img src={currentTicket?.attachment_url} style={{width: '450px'}} />
          )}
        </div>
      </div>
    </div>
  )
}

export {DrawerTicketDetail}

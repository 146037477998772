/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../_metronic/helpers'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'
import { axiosClientAuth } from '../../utils'
import moment from 'moment'

const ManageTemplate: FC = () => {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    axiosClientAuth
      .get('/main/template-mapping')
      .then((res) => {
        setData(res.data.data)
        setFilteredData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const { manageTemplateDateFilter, setListOfPeidForManageTemplate, manageTemplatePeidFilter } =
    useLayout()

  useEffect(() => {
    if (data?.length > 0) {
      const set = new Set()
      data.forEach((t: any) => {
        console.log(t)
        set.add(t.created_by?.pe_id)
      })
      setListOfPeidForManageTemplate(set)
    }
  }, [data])

  useEffect(() => {
    if (
      manageTemplateDateFilter.length == 0 ||
      (manageTemplateDateFilter[0] == '' && manageTemplateDateFilter[1] == '')
    ) {
      setFilteredData(data)
    } else {
      const temp = data?.filter((route: any) => {
        const routeDate = moment(new Date(route?.created_at)).format('DD-MM-YYYY')
        const startDate = moment(new Date(manageTemplateDateFilter[0])).format('DD-MM-YYYY')
        const endDate = moment(new Date(manageTemplateDateFilter[1])).format('DD-MM-YYYY')

        return routeDate >= startDate && routeDate <= endDate
      })

      setFilteredData(temp)
    }
  }, [manageTemplateDateFilter, data])

  useEffect(() => {
    if (manageTemplateDateFilter && manageTemplatePeidFilter.length != 0) {
      const temp = filteredData.filter((t: any) => manageTemplatePeidFilter == t?.created_by?.pe_id)

      setFilteredData(temp)
    } else {
      setFilteredData(data)
    }
  }, [data, manageTemplatePeidFilter])

  return (
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {filteredData && filteredData.length == 0 ? (
        <div className='d-flex justify-content-center flex-column align-items-center'>
          <img
            src='/media/illustrations/sketchy-1/5-dark.png'
            className='h-300px h-lg-350px my-n6'
            alt=''
          />
          <div className='mb-13 text-center'>
            <h1 className='mb-3'>No Data Found !!!</h1>
            <div className='text-muted fw-semibold fs-5'>
              If you need more info, please check{' '}
              <a href='#' className='fw-bold link-primary'>
                Our Guidelines
              </a>
              .
            </div>
          </div>
        </div>
      ) : (
        filteredData?.map((item: any) => {
          return (
            <div className='col-md-4'>
              <div className={`card card-flush px-10 py-5`}>
                <div className='row mb-5'>
                  <div className='col-6'>
                    {item?.status ? (
                      <div className='badge badge-light-success'>Enable</div>
                    ) : (
                      <div className='badge badge-light-dark'>Disabled</div>
                    )}
                  </div>
                  <div className='col-6'></div>
                </div>
                <div
                  className={`d-flex align-items-center bg-light-${Boolean(item.status) ? 'success' : 'dark'
                    } rounded p-5 mb-7`}
                >
                  <span className=' text-success me-5'>
                    <KTIcon iconName='message-text-2' className='text-success fs-1 me-5' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-600 text-hover-primary fs-6'>
                      {item?.created_by?.pe_id}
                    </a>
                    <span className='word-break text-white-800 fw-semibold d-block'>
                      {item.mapped_template_content}
                    </span>
                    <span
                      className='text-muted fw-semibold d-block'
                      style={{ wordBreak: 'break-word' }}
                    >
                      {moment(item.created_at).format('DD MMM YYYY LT')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ManageTemplateWrapper: FC = () => {
  const intl = useIntl()
  const { setRightTolbar } = useLayout()

  useEffect(() => {
    setRightTolbar('managetemplate')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Mapped URLs</PageTitle>
      <ManageTemplate />
    </>
  )
}

export { ManageTemplateWrapper }

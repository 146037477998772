import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { axiosClientAuth } from '../../../../../app/utils'
import { KTIcon } from '../../../../helpers'
import Swal from 'sweetalert2'

const ModalEditUser = ({ show, setShow, currData }) => {
  const [roles, setRoles] = useState([])
  const getData = async () => {
    try {
      const { data: mData } = await axiosClientAuth.get('/auth/api-permission-group')
      setRoles(mData.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const [input, setInput] = useState({
    name: currData?.name,
    email: currData?.email,
    groups: '',
  })

  useEffect(() => {
    setInput({ ...input, name: currData?.name, email: currData?.email, groups: currData?.group_obj?.uuid || '' })
  }, [currData])

  const onChangeHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    axiosClientAuth
      .patch('/auth/role-user', {
        uuid: currData?.uuid,
        groups: input.groups,
      })
      .then((res) => {
        Swal.fire({
          titleText: 'Success!',
          text: 'User updated Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
        }).then((isConfirm) => {
          isConfirm && window.location.reload()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Modal className='modal fade modal-lg' show={show}>
      <div className='modal-header'>
        <h2 className='fw-bold'>Edit User</h2>
        <div className='d-flex justify-content-end'>
          <button
            className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setShow(false)}
          >
            <KTIcon iconName='cross' className='text-gray fs-1' />
          </button>
        </div>
      </div>
      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <form id='kt_modal_add_user_form' className='form' onSubmit={onSubmit}>
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='fv-row mb-7'>
              <label className='required fw-semibold fs-6 mb-2'>Full Name</label>
              <input
                type='text'
                name='user_name'
                className='form-control form-control-solid mb-3 mb-lg-0'
                placeholder='Full name'
                value={input.name}
                disabled
              />
            </div>
            <div className='fv-row mb-7'>
              <label className='required fw-semibold fs-6 mb-2'>Email</label>
              <input
                type='email'
                name='user_email'
                className='form-control form-control-solid mb-3 mb-lg-0'
                placeholder='example@domain.com'
                value={input.email}
                disabled
              />
            </div>
            <div className='mb-7'>
              <label className='required fw-semibold fs-6 mb-5'>Role</label>
              {roles.map((role: any, i) => (
                <React.Fragment key={role.uuid}>
                  <div className='d-flex fv-row'>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input me-3'
                        name='groups'
                        type='radio'
                        value={role.uuid}
                        checked={input.groups == role.uuid}
                        onChange={onChangeHandler}
                      />
                      <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                        <div className='fw-bold text-gray-800'>{role.name}</div>
                        <div className='text-gray-600'>{role.description}</div>
                      </label>
                    </div>
                  </div>
                  <div className='separator separator-dashed my-5' />
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className='text-center pt-15'>
            <button
              type='button'
              className='btn btn-light me-3'
              // data-kt-users-modal-action="cancel"
              onClick={() => {
                setShow(false);
                setInput({
                  name: "",
                  email: '',
                  groups: '',
                })
              }}
            >
              Discard
            </button>
            <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit'>
              <span className='indicator-label'>Submit</span>
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalEditUser

import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { axiosClientAuth } from '../../utils'
const UserManagementRightToolbar = () => {
  const [input, setInput] = useState({
    name: '',
    phone: '',
    email: '',
    pe_id: '',
    groups: '',
  })
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const [roles, setRoles] = useState([]);
  const getData = async () => {
    try {
      const { data: mData } = await axiosClientAuth.get("/auth/api-permission-group")
      setRoles(mData.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    axiosClientAuth.post("/auth/role-user", input).then(resp=>{
      Swal.fire({
        titleText: 'Success!',
        text: 'User Created Successfully',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then((isConfirm) => {
        isConfirm && window.location.reload();
      })
    }).catch(err=>{
      console.log(err);
    })
  }

  const onChangeHandler = (e) => {
    setInput(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  return (
    <>
      <button
        className='btn btn-sm fw-bold btn-primary'
        onClick={() => { setShow(true) }}
      >
        Add User
      </button>

      {/* My Modal */}
      <Modal
        show={show}
        className="modal fade modal-lg"
      >
        {/* <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content"> */}
        <div className="modal-header">
          <h2 className="fw-bold">Add User</h2>
          <div className='d-flex justify-content-end'>
            <button
              className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => setShow(false)}
            >
              <KTIcon iconName='cross' className='text-gray fs-1' />
            </button>
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
          <form className="form" onSubmit={onSubmit}>
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="fv-row mb-7">
                <label className="required fw-semibold fs-6 mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={input.name}
                  onChange={onChangeHandler}
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  placeholder="Full name"
                />
              </div>
              <div className="fv-row mb-7">
                <label className="required fw-semibold fs-6 mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={input.email}
                  onChange={onChangeHandler}
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  placeholder="example@domain.com"
                />
              </div>
              <div className="mb-7">
                <label className="required fw-semibold fs-6 mb-5">Role</label>
                {
                  roles.map((role: any, i) => (
                    <React.Fragment key={role.uuid}>
                      <div className="d-flex fv-row">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-3"
                            name="groups"
                            type="radio"
                            value={role.uuid}
                            checked={input.groups == role.uuid}
                            onChange={onChangeHandler}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="kt_modal_update_role_option_0"
                          >
                            <div className="fw-bold text-gray-800">{role.name}</div>
                            <div className="text-gray-600">
                              {role.description}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-5" />
                    </React.Fragment>
                  ))
                }
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                onClick={() => { setShow(false); }}
              >
                Discard
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                <span className="indicator-label">Submit</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              </button>
            </div>
          </form>
        </div>
        {/* </div>
        </div> */}
      </Modal>
    </>
  )
}

export default UserManagementRightToolbar
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const axiosClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const axiosClientAuth = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${
      localStorage.getItem('kt-auth-react-v')
        ? JSON.parse(localStorage.getItem('kt-auth-react-v') || '')?.access_token
        : ''
    }`,
  },
})

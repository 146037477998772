/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'
import {useLayout} from '../../../layout/core'
import {axiosClientAuth} from '../../../../app/utils'
import {Dropdown} from 'react-bootstrap'
import Swal from 'sweetalert2'

const DrawerMessenger: FC = () => {
  const {currentTicket} = useLayout()

  const {setCurrentTicket} = useLayout()

  const onTicketClick = () => {
    axiosClientAuth
      .get('/support/tcil-ticket?ticket_uuid=' + currentTicket.uuid)
      .then((resp) => setCurrentTicket(resp.data.data))
      .catch((err) => console.log(err))
  }

  const updateStatus = (status) => {
    axiosClientAuth
      .patch('/support/tcil-ticket', {uuid: currentTicket.uuid, status: status})
      .then((resp) => {
        Swal.fire({
          titleText: 'Success!',
          text: 'Status Changed Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
        }).then((isConfirm) => {
          isConfirm && window.location.reload()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
        <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <span className='fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1'>Support</span>
              <div className='mb-0 lh-1'>
                {currentTicket?.status == 'open' ? (
                  <>
                    <span className='badge badge-danger badge-circle w-10px h-10px me-1'></span>
                    <span className='fs-7 fw-semibold text-muted'>Open</span>
                  </>
                ) : currentTicket?.status == 'resolved' ? (
                  <>
                    <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                    <span className='fs-7 fw-semibold text-muted'>Resolved</span>
                  </>
                ) : (
                  <>
                    <span className='badge badge-warning badge-circle w-10px h-10px me-1'></span>
                    <span className='fs-7 fw-semibold text-muted'>Processing</span>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='me-2'>
              {currentTicket.status != 'resolved' && (
                <Dropdown className='text-end' align={'end'}>
                  <Dropdown.Toggle
                    className='p-0 btn-light-primary template-dropdown bg-transparent'
                    style={{paddingRight: '0 !important'}}
                  >
                    <KTIcon iconName='dots-square' className='fs-2 p-0' />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className='ps-4 py-3'>
                      <div className='fs-5 text-dark fw-bolder'>Quick Actions</div>
                    </div>

                    <div className='separator border-gray-200'></div>

                    <div className='menu-item px-5 text-center'>
                      <button
                        className='btn btn-link'
                        // className={clsx('btn btn-link', !temp?.status && 'disabled')}
                        onClick={() => updateStatus('processing')}
                      >
                        Processing
                      </button>
                    </div>
                    <div className='menu-item px-5 text-center'>
                      <button
                        className='btn btn-link'
                        // className={clsx('btn btn-link', temp?.status && 'disabled')}
                        // onClick={() => updateTemplate(temp?.uuid, true)}
                        onClick={() => updateStatus('resolved')}
                      >
                        Resolved
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>

            <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>

        <ChatInner
          isDrawer={true}
          ticketChats={currentTicket?.ticket_chats?.reverse()}
          ticket={currentTicket}
          onTicketClick={onTicketClick}
        />
      </div>
    </div>
  )
}

export {DrawerMessenger}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { KTIcon } from '../../../../helpers'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import moment from 'moment'
import Swal from 'sweetalert2'
import { axiosClientAuth } from '../../../../../app/utils'
import clsx from 'clsx'
import { DrawerURLRedirection } from '../drawers/DrawerURLRedirection'
import { DatePicker } from 'antd'
import { useAuth } from '../../../../../app/modules/auth'

interface urlDataType {
  name: string
  url: string
  created_at: string
  status: boolean
  uuid: string
  is_trusted: boolean
  pe_name: string
}

type Props = {
  className: string
  urlData: urlDataType[]
  filter?: boolean
  title?: string
  subtitle?: string
}

type columnType = {
  [x: string]: any
  name: string
  url: string
  created_at: string
  status: boolean
  is_trusted: boolean
  pe_name: string
}

const TablesWidgetURLScrubbingLogs: React.FC<Props> = ({
  className,
  urlData,
  filter = true,
  title = "URLs Approvals",
  subtitle = "Total URLs : "
}) => {
  const { checkPermission } = useAuth()
  const [mData, setMData] = useState(urlData);

  useEffect(() => {
    if (urlData?.length > 0) {
      setMData(urlData)
    }
  }, [urlData])

  const updateUrl = (uuid: string, status: boolean) =>
    axiosClientAuth
      .patch('/main/blacklist_url', {
        uuid,
        status,
      })
      .then((res) => {
        console.log(res.data.status)
        if (res.data.status) {
          Swal.fire({
            titleText: 'Success!',
            text: 'URL Updated Successfully',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then((isConfirm) => {
            isConfirm && window.location.reload();
          })
        }
      })
      .catch((error) => console.log(error))

  const [urlDrawerData, setUrlDrawerData] = useState()

  const fetchUrlRedirection = (uuid: string) =>
    axiosClientAuth
      .get(`main/requests-redirects?url_uuid=${uuid}`)
      .then((res) => {
        console.log(res)
        setUrlDrawerData(res.data.data)
      })
      .catch((err) => console.log(err))

  const columns: ReadonlyArray<Column<columnType>> = useMemo(
    () => [
      {
        Header: 'PE Details',
        accessor: 'pe_id',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{props.row.original.pe_name}</span>
              <span className='d-block text-muted fw-semibold'>{props.value}</span>
            </>
          )
        },
      },
      {
        Header: 'pe_name', accessor: 'pe_name', show: false, visible: false,
        style: { display: 'none' }
      },
      { Header: 'URL NAME', accessor: 'name' },
      { Header: 'URL', accessor: 'url' },
      {
        Header: 'Trusted URL',
        accessor: 'is_trusted',
        Cell: ({ ...props }) => {
          return props.value === true ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>Yes</span>
          ) : (
            <span className='badge badge-light-danger fw-bold px-4 py-3'>No</span>
          )
        },
      },
      {
        Header: 'DATE',
        accessor: 'created_at',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{moment(props.value).format('DD/MM/YYYY')}</span>
              <span className='d-block text-muted fw-semibold'>
                {moment(props.value).format('LT')}
              </span>
            </>
          )
        },
      },
      {
        Header: 'STATUS',
        accessor: 'is_blacklisted_by_tcil',
        Cell: ({ ...props }) => {
          return props.value === true ? (
            <span className='badge badge-light-danger fw-bold px-4 py-3'>Blacklist</span>
          ) : props.value === false ? (
            <span className='badge badge-light-success fw-bold px-4 py-3'>Whitelist</span>
          ) : (
            <span className='badge badge-light-warning fw-bold px-4 py-3'>Pending</span>
          )
        },
      },
      {
        Header: 'VIEW',
        Cell: ({ ...props }) => {
          const uuid = props.row.original.uuid
          const status = props.row.values.is_blacklisted_by_tcil
          return (
            <>
              <a
                id='url-redirection-toggle'
                href='javascript:void(0)'
                className='btn h-35px w-65px btn-icon btn-light-info me-3'
                onClick={() => fetchUrlRedirection(uuid)}
              >
                View
              </a>
              {checkPermission('URL Verification', 'can_edit') && (
                <>
                  <button
                    className={clsx(
                      'btn h-35px btn-icon btn btn-light-primary me-3',
                      status == false && 'disabled btn-secondary'
                    )}
                    disabled={status == false}
                    onClick={() => updateUrl(uuid, false)}
                  >
                    <KTIcon
                      iconName='check'
                      className={clsx(
                        'text-hover-white fs-1',
                        status == false ? 'text-gray' : 'text-primary'
                      )}
                    />
                  </button>
                  <button
                    className={clsx(
                      'btn h-35px btn-icon btn btn-light-danger',
                      status == true && 'disabled btn-secondary'
                    )}
                    disabled={status == true}
                    onClick={() => updateUrl(uuid, true)}
                  >
                    <KTIcon
                      iconName='cross'
                      className={clsx(
                        'text-hover-white fs-1',
                        status == true ? 'text-gray' : 'text-danger'
                      )}
                    />
                  </button>
                </>
              )}
            </>
          )
        },
      },
    ],
    [urlData]
  )

  const scrubbingLogsTable = useTable(
    {
      columns,
      data: mData,
      initialState: {
        hiddenColumns: columns.map((column: any) => {
          if (column.show === false) return column.accessor || column.id;
        }),
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )
  const { RangePicker } = DatePicker

  const onDateChange = (value, datestring: any) => {
    if (datestring.length == 0 || (datestring[0] == '' && datestring[1] == '')) {
      setMData(urlData)
      return;
    } else {
      const temp = urlData.filter((route) => {
        const routeDate = moment(new Date(route?.created_at)).format("DD-MM-YYYY");
        const startDate = moment(new Date(datestring[0])).format("DD-MM-YYYY");
        const endDate = moment(new Date(datestring[1])).format("DD-MM-YYYY");

        return routeDate >= startDate && routeDate <= endDate
      })
      setMData(temp)
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          <span className='text-muted fw-semibold fs-7'>{subtitle} {scrubbingLogsTable?.rows?.length}</span>
        </h3>
        <div className='d-flex align-items-center'>
          {
            filter ?
              <>
                <RangePicker
                  className='bg-gray-100 text-white border-0 me-2'
                  onChange={onDateChange}
                  style={{ height: '34px' }} />
                <span className='mx-2'>
                  <input type='checkbox' id='trustedCheckBox' onChange={() => scrubbingLogsTable?.headers[4].setFilter(prev => prev ? !prev : true)} checked={scrubbingLogsTable?.headers[4].filterValue || false} />
                  <label className='ms-2' htmlFor="trustedCheckBox">Is Trusted</label>
                </span>

                <select
                  className='form-select form-select-sm form-select-solid w-auto'
                  value={scrubbingLogsTable?.headers[6].filterValue}
                  onChange={(e) => scrubbingLogsTable?.headers[6].setFilter(e.target.value)}
                >
                  {['All', 'false', 'null', 'true'].map((item) => (
                    <option value={item == 'All' ? '' : item}>
                      {
                        item == 'All' ? 'All'
                          : item == 'false' ? 'Whitelisted'
                            : item == 'null' ? 'Pending'
                              : 'Blacklisted'
                      }
                    </option>
                  ))}
                </select>
                <div className='d-flex align-items-center position-relative my-1 ms-2'>
                  <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
                  <input
                    type='text'
                    id='kt_filter_search'
                    className='form-control form-control-solid form-select-sm w-150px ps-9'
                    placeholder='Search URL'
                    value={scrubbingLogsTable?.state.globalFilter}
                    onChange={(e) => scrubbingLogsTable?.setGlobalFilter(e.target.value)}
                  />
                </div>
              </>
              : <>
                <button
                  className='btn h-35px btn-icon btn-primary me-3'
                  style={{ width: "100px" }}
                  onClick={() => window.location.href = "/urlverification"}
                >
                  View All
                </button>
              </>
          }
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...scrubbingLogsTable?.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {scrubbingLogsTable?.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={`
                      ${column.render("Header") == 'Trusted URL' ? 'w-100px' : ''}
                      ${column.render('Header') == 'VIEW' ? 'w-200px' : ''}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...scrubbingLogsTable?.getTableBodyProps()}>
              {(scrubbingLogsTable?.page.length > 0 &&
                scrubbingLogsTable?.page.map((row) => {
                  scrubbingLogsTable?.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          <></>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={scrubbingLogsTable?.state.pageSize}
                onChange={(e) => scrubbingLogsTable?.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {scrubbingLogsTable?.state.pageIndex + 1} of{' '}
                  {scrubbingLogsTable?.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => scrubbingLogsTable?.previousPage()}
                disabled={!scrubbingLogsTable?.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => scrubbingLogsTable?.nextPage()}
                disabled={!scrubbingLogsTable?.canNextPage}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>

      <DrawerURLRedirection urlDrawerData={urlDrawerData} />
    </div>
  )
}

export { TablesWidgetURLScrubbingLogs }

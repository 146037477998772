/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'
import { TablesWidgetBlacklistURL } from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetBlacklistURL'

const BlackListURL: FC = () => (
  <>
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-lg-12'>
        <TablesWidgetBlacklistURL className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </div>
  </>
)

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const BlackListURLWrapper: FC = () => {
  const intl = useIntl()
  const { setRightTolbar } = useLayout();

  useEffect(() => {
    setRightTolbar("blacklisturl")
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>{intl.formatMessage({ id: 'Global Blacklist' })}</PageTitle>
      <BlackListURL />
    </>
  )
}

export { BlackListURLWrapper }
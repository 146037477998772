/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useLayout} from '../../../../layout/core'
import {KTIcon} from '../../../../helpers'
import {axiosClientAuth} from '../../../../../app/utils'
import {getClassNameByStatus} from '../../../../../app/utils/helper'
import Swal from 'sweetalert2'

const DrawerIPList: FC = () => {
  const [data, setData] = useState({
    ipaddress: [],
  })

  const [flag, setFlag] = useState(false)

  const {uuidOfVendorForIpList} = useLayout()

  useEffect(() => {
    if(uuidOfVendorForIpList){
      axiosClientAuth
        .get(`main/ip-address?vendor_id=${uuidOfVendorForIpList}`)
        .then((res) => {
          setData(res.data.data)
        })
        .catch((error) => console.log(error))
    }
  }, [uuidOfVendorForIpList, flag])

  const onStatusChange = (item: any, type: Boolean) => {
    axiosClientAuth
      .patch('main/blacklist_ip', {
        uuid: item.uuid,
        status: type,
      })
      .then((res) => {
        Swal.fire({
          titleText: 'Success!',
          text: 'Status Changed Successfully.',
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        }).then((isConfirm) => {
          if (isConfirm) {
            window.location.reload();
          }
        })
      })
      .catch((error) => {
        Swal.fire({
          titleText: 'Error!',
          text: 'Internal Server Error!!!',
          icon: 'error',
          confirmButtonText: 'Ok! Got It',
        })
      })
  }

  return (
    <div
      id='kt_drawer_ip_list'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_ip_list_toggle'
      data-kt-drawer-close='#kt_drawer_ip_list_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_ip_list_messenger'>
        <div className='card-header pe-5' id='kt_drawer_ip_list_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <h1>IP List</h1>
            </div>
          </div>
          <div className='card-toolbar'>
            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_drawer_ip_list_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>

        <div className='card-body'>
          <div className='timeline'>
            <div className='timeline'>
              {data?.ipaddress?.map((ip: any, index: number) => (
                <div className='row'>
                  <div className='col-8'>
                    <div className='timeline-item'>
                      <div className='timeline-line w-40px'></div>

                      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                        <div
                          className={`symbol-label bg-light-${getClassNameByStatus(
                            ip?.is_blacklisted_by_tcil
                          )}`}
                        >
                          <KTIcon
                            iconName='fasten'
                            className={`fs-2 text-${getClassNameByStatus(
                              ip?.is_blacklisted_by_tcil
                            )}`}
                          />
                        </div>
                      </div>

                      <div className='timeline-content mb-10 mt-2'>
                        <div className='pe-3 mb-5'>
                          <div className='fw-bold text-gray-600 fs-6'>{ip?.name}</div>
                          <div className='fs-5 fw-bold'>{ip?.address}</div>
                          <div className='text-muted fw-semibold'>{ip?.created_at}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-4'>
                    <button
                      className='btn h-35px btn-icon btn btn-light-primary me-3'
                      onClick={() => onStatusChange(ip, false)}
                      disabled={!Boolean(ip?.is_blacklisted_by_tcil)}
                    >
                      <KTIcon iconName='check' className='text-primary text-hover-white fs-1' />
                    </button>
                    <button
                      className='btn h-35px btn-icon btn btn-light-danger me-3'
                      onClick={() => onStatusChange(ip, true)}
                      disabled={Boolean(ip?.is_blacklisted_by_tcil)}
                    >
                      <KTIcon iconName='cross' className='text-danger text-hover-white fs-1' />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DrawerIPList}

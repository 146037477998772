import React, {FC, useEffect} from 'react'
import {TablesWidgetBlacklistURL} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetBlacklistURL'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {TablesWidgetOperatorList} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetOpratorList'

const OperatorData = () => {
  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-12'>
          <TablesWidgetOperatorList className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OperatorDataWrapper: FC = () => {
  const intl = useIntl()
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('OperatorData')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>
        {intl.formatMessage({id: 'Account Statistics'})}
      </PageTitle>
      <OperatorData />
    </>
  )
}

export default OperatorDataWrapper

import React, { FC } from "react";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";

const Invoice = () => {
  return (
    <div className="card">
      <div className="card-body py-20">
        <div className="mw-lg-950px mx-auto w-100">
          <div id="section-to-print">
            <div className="d-flex justify-content-between flex-column flex-sm-row mb-10">
              <h4 className="fw-bolder text-gray-800 fs-2qx pe-5 pb-7">INVOICE</h4>
              <div className="text-sm-end">
                <a href="#" className="d-block mw-150px ms-sm-auto">
                  <img
                    alt="Logo"
                    src="/media/logos/tcil-logo-dark.png"
                    // src="/media/logos/pinnacle-logo.png"
                    className="w-50"
                  />
                </a>
                <div className="text-sm-end fw-semibold fs-4 text-muted mt-7">
                  <div>7, Pinnacle House, Nawab Layout, Tilak Nagar, Near Law College Square</div>
                  <div>Nagpur, India - 440010</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-7 gap-md-10">
              <div className="fw-bold fs-2">
                Dear Harshil,
                <br />
                PE ID - 348583454357845
                <br />
                <span className="fs-6">(parekhharshil99@gmail.com)</span>,
                <br />
                <span className="text-muted fs-5">
                  Here are your order details. We thank you for your purchase.
                </span>
              </div>
              <div className="separator" />
              <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
                <div className="flex-root d-flex flex-column">
                  <span className="text-muted">Date</span>
                  <span className="fs-5">23 December, 2022</span>
                </div>
                <div className="flex-root d-flex flex-column">
                  <span className="text-muted">Invoice ID</span>
                  <span className="fs-5">#INV-000414</span>
                </div>
                <div className="flex-root d-flex flex-column">
                  <span className="text-muted">Billing Address</span>
                  <span className="fs-6">
                    Unit 1/23 Hastings Road, Melbourne 3000, Victoria, Australia.
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-column">
                <div className="table-responsive border-bottom mb-9">
                  <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <thead>
                      <tr className="border-bottom fs-6 fw-bold text-muted">
                        <th className="min-w-100px pb-2">Products</th>
                        <th className="min-w-70px text-end pb-2">Type</th>
                        <th className="min-w-80px text-end pb-2">Order Id</th>
                        <th className="min-w-80px text-end pb-2">Date</th>
                        <th className="min-w-100px text-end pb-2">Total</th>
                      </tr>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="fw-bold">Enterprice plan</div>
                          </div>
                        </td>
                        <td className="text-end">SMS Pack</td>
                        <td className="text-end">order123</td>
                        <td className="text-end">1 Jan, 2023</td>
                        <td className="text-end">₹ 15,000</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="fw-bold">www.harshil.com</div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">URL</td>
                        <td className="text-end">order1234</td>
                        <td className="text-end">1 Feb, 2023</td>
                        <td className="text-end">₹ 10,00,000</td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="text-end">
                          Subtotal
                        </td>
                        <td className="text-end">₹ 10,15,000</td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="text-end">
                          GST (18%)
                        </td>
                        <td className="text-end">₹ 18,000</td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="fs-3 text-dark fw-bold text-end">
                          Grand Total
                        </td>
                        <td className="text-dark fs-3 fw-bolder text-end">
                          ₹ 10,15,000
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-stack flex-row-reverse flex-wrap">
            <button
              type="button"
              className="btn btn-primary my-1"
              onClick={() => window.print()}
            >
              Print Invoice
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Payment Report',
    path: '/myprofile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const InvoiceWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Invoice Details</PageTitle>
      <Invoice />
    </>
  )
}

export { InvoiceWrapper }